import React, { Dispatch } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import {
  ApplicationAction,
  ApplicationState,
  IApplication,
  IAttachment,
  IVideoInterview
} from '../../types';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { Delete, Download, OpenInNew, Visibility } from '@mui/icons-material';
import { styles } from '../../styles';
import NoAttachmentsSVG, { CopyLinkSVG } from '../Icons';
import DocTypeIcon from '../../../Components/Utilities/DocTypeIcon';
import { theme } from '../../../../ThemeContext/ThemeObject';
import AttachmentSelector from '../../../Components/Utilities/AttachmentSelector';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TableSkeleton from '../../../Components/Multiposter/TableSkeleton';
import { IUserPermissions } from '../../../Components/sharedTypes';
import dayjs from 'dayjs';
import {
  categorizeAttachments,
  VIDEO_INTERVIEW_DISPLAY_NAME,
  VIDEO_INTERVIEW_SOURCE_INDEX
} from '../../config';

const BASE_URL = window.location.origin;

function AttachmentsTab({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { apiKey, userTimeZone } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  const { data: combinedData, isLoading } = useQuery({
    queryKey: ['attachments'],
    queryFn: async () => {
      if (application) {
        const [attachmentsResult, videoInterviewResult] = await Promise.allSettled([
          Api.getApplicationAttachments(application.job.id, application.id),
          Api.getVideoInterview(application.id)
        ]);

        const attachmentsData =
          attachmentsResult.status === 'fulfilled'
            ? attachmentsResult.value
            : { res: { attachments: [] } };
        const videoInterviewData =
          videoInterviewResult.status === 'fulfilled' ? videoInterviewResult.value : null;

        const categorizedAttachments = categorizeAttachments(attachmentsData.res.attachments);

        return {
          attachments: categorizedAttachments,
          videoInterview: videoInterviewData?.res || null
        };
      }
      return { attachments: { files: [], videos: [] }, videoInterview: null };
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error fetching data: ${error}`,
          state: 'error'
        }
      });
    },
    enabled: !!application
  });

  function AttachmentTableRow({
    id,
    name,
    publicUrl,
    attachmentTitle,
    attachedBy,
    createdAt,
    format,
    url,
    attachmentType,
    item
  }: {
    id: number | string;
    name: string;
    publicUrl: string;
    attachmentTitle?: string;
    attachedBy: string;
    createdAt?: string;
    format: string | undefined;
    url: string;
    attachmentType: 'files' | 'video';
    item: IAttachment | IVideoInterview;
  }) {
    const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const handleCopy = () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'Link copied to clipboard',
          state: 'success'
        }
      });
    };

    const handleOpenItem = (Item: IAttachment | IVideoInterview) => {
      dispatch({
        type: 'SET_SELECTED_ATTACHMENT_ACTION',
        payload: { ...ApplicationState.selectedAttachmentAction, preview: Item }
      });
    };

    return (
      <TableRow key={id}>
        <TableCell>
          <Box
            id={`${attachmentType === 'video' ? 'video' : 'file'}-details-button`}
            sx={styles.fileIconContainer}
          >
            <Box sx={styles.fileIcon} onClick={() => handleOpenItem(item)}>
              {DocTypeIcon(format)}
            </Box>
            <Box>
              <Stack direction={'row'} alignItems={'center'}>
                <Box sx={styles.fileName} onClick={() => handleOpenItem(item)}>
                  {name}
                </Box>
              </Stack>
              <Box sx={styles.fileDate}>
                {createdAt
                  ? dayjs(createdAt)
                      .tz(userTimeZone || timeZoneString)
                      .format('Do MMMM YYYY[,] h:mma')
                  : '-'}
              </Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell sx={styles.fileAttachedBy}>
          {attachmentTitle === 'N/A' ? '-' : attachmentTitle}
        </TableCell>
        <TableCell sx={styles.fileAttachedBy}>{attachedBy}</TableCell>
        <TableCell align={'right'}>
          <Tooltip title={'Preview'} placement={'top'} arrow>
            <IconButton
              id={`view-${attachmentType === 'video' ? 'video' : 'file'}-button`}
              onClick={() => handleOpenItem(item)}
            >
              <Visibility sx={styles.preview} />
            </IconButton>
          </Tooltip>
          {permissions?.Applications?.['Download Attachments'] && (
            <Tooltip title={'Download'} placement={'top'} arrow>
              <IconButton
                id={`download-${attachmentType === 'video' ? 'video' : 'file'}-button`}
                sx={styles.tableActionIcon}
                href={url}
              >
                <Download sx={{ fontSize: 16, color: theme.palette.common.grey }} />
              </IconButton>
            </Tooltip>
          )}
          {attachmentType === 'video' && (
            <>
              <CopyToClipboard
                id="copy-video-link-button"
                text={BASE_URL + publicUrl}
                onCopy={handleCopy}
              >
                <Tooltip title={'Copy link'} placement={'top'} arrow>
                  <IconButton sx={styles.tableActionIcon}>
                    <CopyLinkSVG />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
              <Tooltip title={'Open video in new tab'} placement={'top'} arrow>
                <IconButton
                  id="open-video-in-new-tab-button"
                  href={publicUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <OpenInNew sx={{ fontSize: 16, color: theme.palette.common.grey }} />
                </IconButton>
              </Tooltip>
            </>
          )}
          {permissions?.Applications['Delete Application Attachments'] && (
            <Tooltip title={'Delete'} placement={'top'} arrow>
              <IconButton
                id={`delete-${attachmentType === 'video' ? 'video' : 'file'}-button`}
                sx={styles.tableActionIcon}
                onClick={() => {
                  dispatch({
                    type: 'SET_SELECTED_ATTACHMENT_ACTION',
                    payload: { ...ApplicationState.selectedAttachmentAction, delete: item }
                  });
                }}
              >
                <Delete sx={{ color: theme.palette.error.main, fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
  }

  function AttachmentTable({
    attachmentsList,
    attachmentType
  }: {
    attachmentsList: IAttachment[];
    attachmentType: 'files' | 'video';
  }) {
    return (
      <>
        {attachmentsList.map((attachment: IAttachment) => (
          <AttachmentTableRow
            key={attachment.id}
            id={attachment.id}
            name={attachment.attached_file_name}
            publicUrl={attachment.public_url}
            attachmentTitle={attachment.attachment_title}
            attachedBy={attachment.attached_by}
            createdAt={attachment.created_at}
            format={attachment.attached_file_name.split('.').pop()}
            url={
              attachmentType === 'video'
                ? attachment.attached_url
                : `${BASE_URL}/admin/assets/${attachment.id}`
            }
            attachmentType={attachmentType}
            item={attachment}
          />
        ))}
      </>
    );
  }

  const getAttachmentAvailable = () => {
    let hasVideo = false;
    let hasFile = false;
    let hasVideoInterview = false;

    if (!isLoading && combinedData) {
      hasVideo = combinedData.attachments?.videos.length > 0;
      hasFile = combinedData.attachments?.files.length > 0;
      hasVideoInterview = !!combinedData.videoInterview;
    }

    return {
      hasVideo,
      hasFile,
      hasVideoInterview
    };
  };
  const { hasVideo, hasFile, hasVideoInterview } = getAttachmentAvailable();

  function AttachmentTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell>File Name</TableCell>
          <TableCell>Attachment Title</TableCell>
          <TableCell>Attached by</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Stack sx={styles.attachmentsContainer}>
      <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Attachments</Stack>
      {isLoading && (
        <TableSkeleton size={4} borderType={'none'} height={'60px'} padding={'5px 0px'} />
      )}
      {!isLoading && combinedData && !hasVideo && !hasFile && !hasVideoInterview && (
        <Box sx={styles.noAttachments}>
          <NoAttachmentsSVG />
          <Box sx={styles.emptyStateText}>No attachments added</Box>
        </Box>
      )}
      {!isLoading && combinedData && (
        <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
          <Table sx={styles.tableWrapper} aria-label="application attachments">
            <AttachmentTableHead />
            {(hasVideo || hasVideoInterview) && (
              <>
                <TableBody>
                  {combinedData.videoInterview && (
                    <AttachmentTableRow
                      id={combinedData.videoInterview.id}
                      name={VIDEO_INTERVIEW_DISPLAY_NAME}
                      publicUrl={combinedData.videoInterview.watch_interview_link}
                      attachedBy="-"
                      format={
                        combinedData.videoInterview.sources[VIDEO_INTERVIEW_SOURCE_INDEX].format
                      }
                      url={combinedData.videoInterview.sources[VIDEO_INTERVIEW_SOURCE_INDEX].url}
                      attachmentType="video"
                      item={combinedData.videoInterview}
                    />
                  )}
                  <AttachmentTable
                    attachmentsList={combinedData.attachments.videos}
                    attachmentType="video"
                  />
                </TableBody>
              </>
            )}
            {hasFile && (
              <>
                <TableBody>
                  <AttachmentTable
                    attachmentsList={combinedData.attachments.files}
                    attachmentType="files"
                  />
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      )}
      {permissions?.Applications?.['Upload File'] && application && (
        <AttachmentSelector
          heightOverride={'100px'}
          apiKey={apiKey}
          performUpload={true}
          maxSize={50000000}
          uploadUrl={`${BASE_URL}/api/v4/jobs/${application.job.id}/applications/${application.id}/attachments`}
          onUploadComplete={() => {
            dispatch({
              type: 'SET_SNACKBAR',
              payload: {
                message: 'File successfully uploaded',
                state: 'success'
              }
            });
            queryClient.removeQueries(['attachments']);
          }}
          attachment={true}
          compact={true}
          maxVideoSize={500000000}
          buttonId="choose-file-button"
        />
      )}
    </Stack>
  );
}

export default AttachmentsTab;