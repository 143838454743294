import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Modal,
  TextField
} from '@mui/material';
import moment from 'moment';

import {
  FormTextField,
  MultilineFormTextField
} from '../../../Components/CustomUIElements/FormTextField';
import { SingleDateTimePicker } from '../../../Components/CustomUIElements/SingleDatePicker';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import StyledSnackbar from '../../../Components/CustomUIElements/StyledSnackbar';
import FormSelectWithIconField from '../../../Components/CustomUIElements/FormSelectWithIconField';

import Api from '../../API';

import { IJobsForTask } from '../../types';
import { IAssignedUserOptionsType } from '../../../Jobs/types';

import { classes } from './styles';

const priorities = [
  { label: 'Low', value: 'Low', color: '#8CCF95' },
  { label: 'Normal', value: 'Normal', color: '#E6A052' },
  { label: 'High', value: 'High', color: '#E37D7A' }
];

export default function AddTasks({
  apiKey,
  jobId,
  setActionsAnchorEl,
  buttonElement,
  isDashboard,
  refetch
}: {
  apiKey: string;
  jobId?: number;
  setActionsAnchorEl?: (value: null) => void;
  buttonElement?: ReactElement;
  isDashboard: boolean;
  refetch?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState<IJobsForTask[]>([]);
  const [selectedJob, setSelectedJob] = useState<IJobsForTask>();
  const [selectedApplicant, setSelectedApplicant] = useState<IJobsForTask['applications'][0]>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [date, setDate] = useState(moment().toDate());
  const [subject, setSubject] = useState('');
  const [note, setNote] = useState('');
  const [assignedUserOptions, setAssignedUserOptions] = useState<IAssignedUserOptionsType[] | []>(
    []
  );
  const [priority, setPriority] = useState({
    label: 'Low',
    value: 'Low',
    color: '#8CCF95'
  });
  const [assignTo, setAssignTo] = useState<IAssignedUserOptionsType | null>(null);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    state: 'success' | 'warning' | 'error';
  }>({
    message: '',
    state: 'success'
  });

  const fieldRefs = {
    subject: useRef<HTMLInputElement>(null),
    note: useRef<HTMLInputElement>(null),
    date: useRef<HTMLInputElement>(null),
    priority: useRef<HTMLInputElement>(null),
    assignTo: useRef<HTMLInputElement>(null)
  };

  const fetchCreateFormData = useCallback(async () => {
    const response = await Api.getTask({ 'X-api-authenticate': apiKey });
    setAssignedUserOptions(response?.assignable_users);
  }, [apiKey]);

  const fetchJobs = useCallback(async () => {
    const response = await Api.getJobs();
    setJobs(response.jobs);
  }, []);

  function dateIsValid(date: Date) {
    return date instanceof Date;
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchCreateFormData(), fetchJobs()]);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [fetchCreateFormData, fetchJobs]);

  function getPriority(value: string) {
    switch (value) {
      case 'Low':
        return 1;
      case 'Normal':
        return 0;
      default:
        return -1;
    }
  }

  function getNewTaskId(): { job_id: number } | { application_id: number } | undefined {
    if (!isDashboard && jobId) {
      return { job_id: jobId };
    }
    if (selectedJob && selectedApplicant) {
      return { application_id: selectedApplicant.id };
    }
    if (selectedJob && !selectedApplicant) {
      return { job_id: selectedJob.id };
    }
    return undefined;
  }

  const createNewTask = async () => {
    if (subject && note && assignTo && priority) {
      if (dateIsValid(new Date(date))) {
        setHasError(false);
      } else return setHasError(true);
    } else {
      let firstError: keyof typeof fieldRefs | undefined = undefined;
      if (!assignTo) firstError = 'assignTo';
      if (!priority) firstError = 'priority';
      if (!note) firstError = 'note';
      if (!subject) firstError = 'subject';
      firstError &&
        fieldRefs[firstError]?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      return setHasError(true);
    }

    try {
      await Api.newTask(
        { 'X-api-authenticate': apiKey },
        {
          task: {
            subject: subject,
            note: note,
            due_at: date.toString(),
            priority: getPriority(priority.value),
            user_id: assignTo.id
          }
        },
        getNewTaskId()
      );
      setSnackbar({
        message: 'Your Task was created',
        state: 'success'
      });
      refetch && refetch();
      setSubject('');
      setNote('');
      setIsDialogOpen(false);
    } catch (error) {
      setSnackbar({
        message: 'There was an error adding a task. Please try again.',
        state: 'error'
      });
    } finally {
      !!setActionsAnchorEl && setActionsAnchorEl(null);
      setIsLoading(false);
      handleClose();
    }
  };

  const handleOpenDialog = () => {
    // Passively loading on click
    fetchCreateFormData();
    fetchJobs();
    setIsDialogOpen(true);
  };

  function handleClose() {
    setSelectedApplicant(undefined);
    setSelectedJob(undefined);
    setDate(moment().toDate());
    setAssignTo(null);
    setSubject('');
    setNote('');
    setIsDialogOpen(false);
  }

  return (
    <Box>
      <Box onClick={handleOpenDialog} id="add-task-job-button">
        {buttonElement || 'Add Task'}
      </Box>
      <Modal open={isDialogOpen} aria-labelledby="modal-modal-title" onClose={handleClose}>
        <Box sx={classes.modalContent}>
          <CloseIcon onClick={handleClose} sx={classes.closeIcon} />
          <Box id="modal-modal-title" sx={classes.modalTitle}>
            Add task
          </Box>
          <Box sx={{ marginTop: '30px', display: 'grid', overflow: 'auto' }}>
            {isDashboard && (
              <Box sx={classes.modalFormLine} pt={3}>
                <Autocomplete
                  disablePortal
                  options={jobs}
                  ref={fieldRefs.assignTo}
                  getOptionLabel={(option) => option.title}
                  id="add-task-select-job"
                  value={selectedJob}
                  onChange={(_event, value) => setSelectedJob(value || undefined)}
                  sx={{ width: '290px', ...sharedClasses.formAutocomplete }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Job" InputLabelProps={{ shrink: true }} />
                  )}
                />
                {!!selectedJob?.applications.length && (
                  <Autocomplete
                    disablePortal
                    options={selectedJob.applications}
                    ref={fieldRefs.assignTo}
                    getOptionLabel={(option) => option.name}
                    id="add-task-assign-to-candidate"
                    value={selectedApplicant}
                    onChange={(_event, value) => setSelectedApplicant(value || undefined)}
                    sx={{ width: '290px', ...sharedClasses.formAutocomplete }}
                    ListboxProps={{
                      style: {
                        fontFamily: 'Source Sans Pro, sans-serif',
                        color: '#333333',
                        maxHeight: '200px'
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assign to candidate"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                )}
              </Box>
            )}
            <Box sx={classes.modalFormLine}>
              <FormTextField
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                styles={{ width: '290px' }}
                error={!subject && hasError ? 'This field is required' : ''}
                innerRef={fieldRefs.subject}
                label={'Subject'}
              />
            </Box>
            <Box sx={classes.modalFormLine}>
              <MultilineFormTextField
                value={note}
                onChange={(e) => setNote(e.target.value)}
                styles={{ width: '450px', marginTop: '20px' }}
                rows={6}
                error={!note && hasError ? 'This field is required' : ''}
                innerRef={fieldRefs.note}
                label={'Note'}
              />
            </Box>
            <Box sx={classes.modalFormLine}>
              <FormControl variant="standard">
                <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
                  Date
                </InputLabel>
                <SingleDateTimePicker
                  value={date}
                  minDate={moment().toDate()}
                  inputStyles={classes.datePicker}
                  pickerStyles={classes.calendar}
                  onChange={(date) => setDate(moment(date).toDate())}
                />
              </FormControl>
              <FormSelectWithIconField
                options={priorities}
                defaultValue="Low"
                onChange={(value) => setPriority(priorities.filter((p) => p.label === value)[0])}
                label={'Priority'}
                styles={{
                  width: '290px',
                  '& .MuiSvgIcon-root': { color: priority.color }
                }}
              />
            </Box>
            <Box sx={{ marginTop: '1.75rem' }}>
              {!isLoading || assignedUserOptions ? (
                <Box sx={classes.modalFormLine}>
                  <Box sx={{ position: 'relative' }}>
                    <Autocomplete
                      disablePortal
                      options={assignedUserOptions}
                      ref={fieldRefs.assignTo}
                      getOptionLabel={(option) => `${option?.firstname} ${option?.lastname}`}
                      id="state-region-province"
                      value={assignTo}
                      onChange={(_event, value) => setAssignTo(value)}
                      sx={{
                        width: '290px',
                        ...sharedClasses.formAutocomplete
                      }}
                      ListboxProps={{
                        style: {
                          fontFamily: 'Source Sans Pro, sans-serif',
                          color: '#333333',
                          maxHeight: '200px'
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assign to user"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                    {!assignTo && hasError && (
                      <Box sx={{ ...sharedClasses.errorBox }}>This field is required</Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <CircularProgress size={20} color="inherit" />
              )}
            </Box>
          </Box>
          <Box sx={classes.modalActions}>
            <Button
              disableElevation
              sx={{ ...classes.buttonRedLight }}
              variant="text"
              onClick={() => {
                setIsDialogOpen(false);
                !!setActionsAnchorEl && setActionsAnchorEl(null);
              }}
            >
              Cancel
            </Button>
            <Button sx={classes.revertloadButton} onClick={() => createNewTask()}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <StyledSnackbar
        message={snackbar.message}
        state={snackbar.state}
        setSnackbarState={setSnackbar}
      />
    </Box>
  );
}
