import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Application from './Application';

export interface IApplicationPageProps {
  applicationId: number;
  jobId: number;
  showLinkedInURL: boolean;
  aiSummariesEnabled: boolean;
  currentUser: { id: number; api_key: string; admin: boolean; timeZone: string };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export default function ApplicationPage({
  applicationId,
  jobId,
  showLinkedInURL,
  aiSummariesEnabled,
  currentUser
}: IApplicationPageProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Application
        {...{ applicationId, jobId, showLinkedInURL, aiSummariesEnabled, currentUser }}
      />
    </QueryClientProvider>
  );
}
