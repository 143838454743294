import React, { useMemo, useReducer, Dispatch, SetStateAction, useEffect } from 'react';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import dashboardStyles from '../../styles';
import Api from '../../API';
import { useInfiniteQuery } from '@tanstack/react-query';
import { theme } from '../../../../ThemeContext/ThemeObject';
import EventItem from './components/EventItem';
import dayjs from 'dayjs';
import Loader from '../Loader';
import { copyEventLinks } from './copyEventLinks';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DoneIcon from '@mui/icons-material/Done';
import { InitialScoutCalendarState, ScoutCalendarReducer } from '../../../../ScoutCalendar/reducer';
import ViewEvent from '../../../../ScoutCalendar/ViewEvent';
import CreateEvent from '../../../../ScoutCalendar/CreateEvent/CreateEvent';
import { IUserPermissions } from '../../../Components/sharedTypes';

function UpcomingEvents({
  setSnackbar,
  apiKey,
  currentUserId,
  newCalendarEnabled,
  userPermissions,
  userTimezone
}: {
  setSnackbar: Dispatch<
    SetStateAction<{ message: string; state: 'success' | 'warning' | 'error' }>
  >;
  apiKey: string;
  currentUserId: number;
  newCalendarEnabled: boolean;
  userPermissions: IUserPermissions;
  userTimezone: string;
}) {
  const [ScoutCalendarState, dispatch] = useReducer(
    ScoutCalendarReducer,
    InitialScoutCalendarState
  );
  const [copied, setCopied] = React.useState(false);
  const { isLoading, isSuccess, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['getEvents'],
      queryFn: ({ pageParam = 1 }) => Api.getEvents({ page: pageParam }),
      getNextPageParam: (lastPage) => lastPage.nextPage || undefined
    });

  function handleScroll(e: React.UIEvent<HTMLDivElement>) {
    if (!e) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight) {
      if (!isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    }
  }

  // Need to recalculate isFirst & isLast when new data is fetched
  const allItems = useMemo(() => {
    const items = data?.pages.flatMap((page) => page.data.events);
    if (!items?.length) return [];
    return items.map((item, index) => {
      const dateCurrent = dayjs(items[index].date).format('YYYY-MM-DD');
      const datePrevious = dayjs(items[index - 1]?.date).format('YYYY-MM-DD');
      const dateNext = dayjs(items[index + 1]?.date).format('YYYY-MM-DD');
      const isFirst = dateCurrent !== datePrevious;
      const isLast = dateCurrent !== dateNext || (index === items.length - 1 && !hasNextPage);
      return {
        ...item,
        isFirst,
        isLast
      };
    });
  }, [data, hasNextPage]);

  // the api key is needed because the events endpoints are using api v2 base controller that requires api key
  useEffect(() => {
    dispatch({ type: 'SET_APIKEY', payload: apiKey });
    dispatch({ type: 'SET_PERMISSIONS', payload: userPermissions });
  }, [apiKey, userPermissions]);

  return (
    <Box sx={{ ...dashboardStyles.section, ...styles.section }}>
      <Box sx={styles.headerContainer}>
        <Box sx={{ ...dashboardStyles.sectionTitle }}>Upcoming events</Box>
        <Tooltip title={copied ? 'Copied!' : 'Copy event links'} placement="top">
          <IconButton
            onClick={() => {
              copyEventLinks(allItems);
              setCopied(true);
              setTimeout(() => {
                setCopied(false);
              }, 1000);
            }}
          >
            {copied ? (
              <DoneIcon sx={{ color: theme.palette.primary.main }} />
            ) : (
              <ContentCopyIcon sx={{ color: theme.palette.primary.main }} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={styles.taskContainer} onScroll={handleScroll} mr={4} ml={4}>
        {isLoading &&
          Array(3)
            .fill(null)
            .map((_, i) => <Loader key={i} />)}
        {isSuccess && (
          <>
            {allItems?.length ? (
              <>
                {allItems.map((item) => (
                  <EventItem
                    key={item.id}
                    event={item}
                    ScoutCalendarState={ScoutCalendarState}
                    dispatch={dispatch}
                    newCalendarEnabled={newCalendarEnabled}
                    userTimezone={userTimezone}
                  />
                ))}
                {isFetchingNextPage && hasNextPage && (
                  <Box display="flex" flex={1} justifyContent="center">
                    <CircularProgress size={20} sx={{ color: theme.palette.primary.main }} />
                  </Box>
                )}
              </>
            ) : (
              <Box>No upcoming events</Box>
            )}
          </>
        )}
      </Box>
      {ScoutCalendarState.showCreateEventModal && (
        <CreateEvent
          events={allItems}
          ScoutCalendarState={ScoutCalendarState}
          dispatch={dispatch}
          setSnackbar={setSnackbar}
        />
      )}
      {ScoutCalendarState.showViewEventModal && (
        <ViewEvent
          events={allItems}
          ScoutCalendarState={ScoutCalendarState}
          dispatch={dispatch}
          setSnackbar={setSnackbar}
          currentUserId={currentUserId}
          userTimezone={userTimezone}
        />
      )}
    </Box>
  );
}

const styles = {
  section: {
    flexBasis: '40%',
    flexGrow: 1,
    maxHeight: '420px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  taskContainer: {
    paddingBottom: '20px',
    overflowY: 'scroll',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '26px',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& .MuiSkeleton-root': {
      transformOrigin: '0px 0px !important'
    },
    '& .spinner': {
      margin: 'auto'
    }
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '30px'
  }
};

export default UpcomingEvents;
